import React, { useRef, useState, Children } from "react"
import styled from "styled-components/macro"
import Utils from "../../consts/Utlis"
import Colors from "../../consts/Colors"

const Button = ({ className, children, type, onClick, secondary }) => {
  return (
    <Wrapper
      type={type}
      className={className}
      onClick={onClick}
      secondary={secondary}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${props => (props.secondary ? "#fff" : Colors.red)};
  display: inline-block;
  margin: 25px auto 0;
  border-radius: 5px;
  text-align: center;
  padding: ${props => (props.type == "large" ? "25px 68px" : "15px 48px")};
  cursor: pointer;
  border: ${props =>
    props.secondary
      ? "1px solid rgba(112, 112, 112, 0.3)"
      : "1px solid #d53000"};
  span {
    color: ${props => (props.secondary ? "rgba(0,0,0,0.5)" : "#fff")};
    ${Utils.fontSize(14, 16, "800px")}
  }
`

export default Button
