import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import Colors from "../../consts/Colors"
import Utils from "../../consts/Utlis"

const RegisterHeader = ({ step }) => {
  return (
    <Wrapper>
      <Sub>Sign up | Step {step} / 2</Sub>
      <Title>{step == 1 ? "ABOUT YOU" : "PAYMENT DETAILS"}</Title>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${Colors.red};
  padding: 2rem;
`

const Sub = styled.div`
  ${Utils.fontSize(14, 20, "1200px")}
  color: #fff;
`
const Title = styled.div`
  ${Utils.fontSize(22, 63, "1200px")}
  color: #fff;
  font-family: "Nanum Myeongjo", serif;
`

export default RegisterHeader
