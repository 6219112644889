import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components/macro"
import Utlis from "../consts/Utlis"
import Devices from "../consts/Devices"
import Colors from "../consts/Colors"
import { useSpring, animated } from "react-spring"
import RegisterHeader from "../components/register-header/RegisterHeader"
import Button from "../components/button/Button"

const RegisterPage = () => {
  const [step, setStep] = useState(0)
  return (
    <Wrapper>
      <RegisterHeader step={step + 1} />
      <Form>
        {step == 0 && (
          <>
            <Field>
              <Title>CONTACT DETAILS</Title>
              <input type="text" placeholder="Your Name" />
              <input type="text" placeholder="Your Email Address" />
            </Field>
            <Field>
              <Title>Your Address</Title>
              <input type="text" placeholder="House Number" />
              <input type="text" placeholder="Street Address" />
              <input type="text" placeholder="Post Code" />
              <input type="text" placeholder="Country" />
            </Field>
          </>
        )}
        {step == 1 && (
          <>
            <Field>
              <Title>CARD HOLDER</Title>
              <input type="text" placeholder="Cardholder Name" />
            </Field>
            <Field>
              <Title>CARD DETAILS</Title>
              <input type="text" placeholder="Card Number" />
              <input type="text" placeholder="Card Expiry Date" />
              <input type="text" placeholder="Card CSV" />
            </Field>
            <Field>
              <Title>Billing Address</Title>
              <input type="text" placeholder="House Number" />
              <input type="text" placeholder="Street Address" />
              <input type="text" placeholder="Post Code" />
              <input type="text" placeholder="Country" />
            </Field>
          </>
        )}
      </Form>

      <Controls>
        {step == 1 && (
          <ButtonComp
            onClick={() => {
              setStep(0)
              if (typeof window !== `undefined`) {
                window.scrollTo(0, 0)
              }
            }}
            secondary={true}
          >
            <span>Previous Step</span>
          </ButtonComp>
        )}
        <ButtonComp
          onClick={() => {
            if (step == 0) {
              setStep(1)
              if (typeof window !== `undefined`) {
                window.scrollTo(0, 0)
              }
            } else {
              localStorage.setItem("onboard", true)
              navigate("/", { replace: true })
            }
          }}
        >
          <span>{step == 0 ? "Next Step" : "Continue"}</span>
        </ButtonComp>
      </Controls>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f8f8f8;
  min-height: 100vh;
`

const Form = styled.div`
  padding: 4rem 1rem;
  max-width: 600px;
  margin: 0 auto;

  div:last-of-type {
    margin-bottom: 0;
  }
`

const Field = styled.div`
  input {
    width: 100%;
    display: block;
    background: #fff;
    border: 1px solid rgba(112, 112, 112, 0.2);
    margin: 1rem 0;
    font-size: 16px;
    font-family: "Work Sans", sans-serif;
    padding: 0.75rem;
    border-radius: 5px;
  }

  input:last-of-type {
    margin-bottom: 0;
  }

  margin-bottom: 4rem;
`
const Title = styled.div`
  font-size: 18px;
  font-family: "Nanum Myeongjo", serif;
  text-transform: uppercase;
`

const Controls = styled.div`
  padding-bottom: 4rem;
  display: flex;
  justify-content: center;
`

const ButtonComp = styled(Button)`
  margin: 0 15px;
`

export default RegisterPage
